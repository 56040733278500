<template>
  <div v-if="custDetails !== null" class="mt-4">
    <div
      class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full ">
      <div class="flex justify-center items-center">
        <h1 v-if="activeUer.userDetailId === custDetails.userDetailId" class="heading-1 text-center">My Profile</h1>
        <h1 v-else class="heading-1 text-center">Employee Detail</h1>
        <div class="absolute right-7 flex items-center gap-3">
          <div class=" text-primary heading-4 absolute right-0 cursor-pointer bg-white px-2 py-1 rounded-xl" @click="editEmployee()">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>
        </div>
      </div>
    </div>
    <div>
       <div class="lg:grid grid-cols-5 gap-4">
        <div class="col-span-2 my-4">
          <div class="card  bg-white rounded-xl p-4 mb-4">
            <div class="">
              <p class="text-gray3">First Name</p>
              <p class="text-text2" v-if="custDetails.firstName !== ''">{{ custDetails.firstName }}</p>
              <p class="text-text2" v-else>-</p>
            </div>
            <div class="mt-4">
              <p class="text-gray3">Last Name</p>
              <p class="text-text2" v-if="custDetails.lastName !== ''">{{ custDetails.lastName }}</p>
              <p class="text-text2" v-else>-</p>
            </div>
            <!-- <div class="mt-4">
              <p class="text-gray3">Number</p>
              <p class="text-text2" v-if="custDetails.cellNumber !== ''">{{custDetails.cellNumber | phone}}</p>
              <p class="text-text2" v-else>-</p>
            </div> -->
            <div class="mt-4">
              <p class="text-gray3">Username</p>
              <p class="text-text2">{{custDetails.userName}}</p>
            </div>
            <div class="mt-4">
              <p class="text-gray3">Email</p>
              <p class="text-text2">{{custDetails.emailAddress}}</p>
              <p v-if="!activeUer.isEmailVerify" class="text-error heading-6">Your email is not verified. <span class="text-primary cursor-pointer" @click="sendOtp()">Click here</span> to verify</p>
            </div>
            <div class="mt-4 flex items-center justify-between" v-if="custDetails.isApproved">
              <div>
                <p class="text-gray3">Status</p>
                <p class="text-text2" v-if="custDetails.isActive">Active</p>
                <p class="text-text2" v-if="!custDetails.isActive">Inactive</p>
              </div>
              <div>
                <p class="text-gray3 text-right">Added On</p>
                <p class="text-text2">{{custDetails.registeredOn | dateToHowManyAgo}}</p>
              </div>
            </div>
            <div class="mt-4">
              <p class="text-gray3">Display Color</p>
              <button style="height: 28px; width:40px" :style="`background: ${custDetails.displayColor}`"  class=" shadow-xl border cta-text py-2 px-5 rounded cursor-default"></button>
            </div>
          </div>
          <div class="card  bg-white rounded-xl p-4 mb-4 cursor-pointer" @click="removeConfirm = true" v-if="$route.query.showDelele && custDetails.isOwner">
            <p class="text-error">Delete My Account</p>
          </div>
          <div class="card  bg-white rounded-xl p-4 mb-4" v-if="!custDetails.isApproved">
            <div class="">
              <div class="flex items-center justify-between">
                <div>
                  <p class="text-gray3">Status</p>
                  <p class="text-text2">Approval Pending</p>
                </div>
                <div>
                  <p class="text-gray3 text-right">Requested On</p>
                  <p class="text-text2">{{custDetails.registeredOn | formatTime}}</p>
                </div>
              </div>
              <div class="flex items-center justify-between pt-4">
                <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Reject'" @buttonAction="statusAppBtn(true)"/>
                <Button  :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Approve'" @buttonAction="statusAppBtn(false)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-2 my-4">
          <div class="card  bg-white rounded-xl p-4 mb-4">
            <p class="text-gray3">Permission</p>
            <p class="divider"></p>
            <div class="mt-2">
              <div class="flex items-center justify-between">
                <p class="text-text2 heading-4">Admin User</p>
                <p class="text-text2">{{custDetails.isAdmin ? 'Yes' : 'No'}}</p>
              </div>
              <p class="text-text2 heading-4 mt-2">Shift</p>
              <div class="flex items-center justify-between">
                <p class="text-gray3">Clock in/Out Selfie is required</p>
                <p class="text-text2">{{custDetails.selfieRequiredForShift ? 'Yes' : 'No'}}</p>
              </div>
              <div class="flex items-center justify-between">
                <p class="text-gray3">Track location for ongoing shift</p>
                <p class="text-text2">{{custDetails.locationTrackingRequiredForShift ? 'Yes' : 'No'}}</p>
              </div>
              <p class="text-text2 heading-4 mt-2">Visit</p>
              <div class="flex items-center justify-between">
                <p class="text-gray3">Visit start/end Selfie is required</p>
                <p class="text-text2">{{custDetails.selfieRequiredForJob ? 'Yes' : 'No'}}</p>
              </div>
              <div class="flex items-center justify-between">
                <p class="text-gray3">Track location while visit is running</p>
                <p class="text-text2">{{custDetails.locationTrackingRequiredForJob ? 'Yes' : 'No'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_overlay relative px-4" v-if="openVerification">
       <div style="width: 400px;" class="custom_dialog rounded-xl h-min p-4">
        <div class="text-center">
            <p class="text-gray4 heading-4 mb-2">Verify your Login Pin</p>
        </div>
        <p class="divider"></p>
        <div class=" col-span-2 my-4">
              <TextField
              :inputId="'otpinputvalueid1'"
              :inputext="otpValue"
              :placholderText="``"
              :lableText="'Enter Existing Login Pin'"
              :isOtp="true"
              :inputType="'password'"
              :autoFocus="true"
              @enterkeyPressAction="checkOtpValue"
              @inputChangeAction="(data) => otpValue = data"  />
          </div>
        <div class="flex gap-2 justify-end mt-4">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="openVerification = false;otpValue=''"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Next'" @buttonAction="checkOtpValue" :disabled="isBtnDis"/>
        </div>
      </div>
    </div>
    <div class="popup_overlay relative px-4" v-if="removeConfirm">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-2 font-semibold">
            <p class="heading-5 text-error whitespace-pre-line"><i class="fa-solid fa-circle-exclamation pr-2"></i>If you delete this account:</p>
          </div>
          <div  class="mb-5 ">
            <div class="heading-6 text-text1 p-1 font-semibold">
              <li>All organization data will be deleted.</li>
            </div>
            <div class="heading-6 text-text1 p-1 font-semibold">
              <li>All employee data get erased.</li>
            </div>
            <div class="heading-6 text-text1 p-1 font-semibold">
              <li>All organization data will be deleted.</li>
            </div>
            <div class="my-2">
                <Dropdown 
                :inputext="employeeSearchText" 
                :inputId="'searchwp-CustomerA'"
                :lableText="'Select Alternate Employee'"
                :placholderText="`Select Alternate Employee`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="true"
                :fieldError="custErr !== ''"
                :keyName="'fullName'"
                :listId="'empreqlistid'"
                :items="employeeList"
                @enterkeyPressAction="custErr =''"
                @selectNewForVal="selectNewCustomer"
                />
                <p v-if="custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
            </div>
            <div class="pt-4">
              <TextField
              :inputId="'yesinputText'"
              :showcharLimit="false"
              :inputext="yesinputText"
              lableText="Type 'Delete' in below text box to deete Organization"
              :autoFocus="true"
              @inputChangeAction="(data) => yesinputText = data"  />
            </div>
            <div class="heading-6 text-error p-1 pt-0 font-normal">
              Once deleted you will be send to main screen.
            </div>
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="`Cancel`" @buttonAction="closeDialog"/>
            <Button :disabled="yesinputText.toLowerCase() !== 'delete'" :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" btnText="Delete" @buttonAction="deleteAccount"/>
          </div>
    </div>
  </div>
    <ConfirmBox v-if="confirmPopup"  :message="'Once rejected, employee need to register again in the App.'" :title="'Are you sure to Reject Request?'" />
    <GlobalOtpVerify v-if="verifyEmail && tokenGenerated !== ''" :verifyEmail="true" :emailAddress="custDetails.emailAddress" :otptoken="tokenGenerated" />
  </div>
</template>
<script>
import Dropdown from '@/View/components/dropdown.vue'
import TextField from '@/View/components/textfield.vue'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import {removeCookies} from '@/utils/cookies'
import GlobalOtpVerify from '../../components/globalOtpVerify.vue'
export default {
  name: "customer",
  components: {
    Button,
    ConfirmBox,
    Dropdown,
    TextField,
    GlobalOtpVerify,
  },
  data() {
    return {
      verifyEmail: false,
      tokenGenerated: null,
      activeUer: null,
      alternateId: 0,
      custErr: '',
      employeeList: [],
      employeeSearchText: '',
      yesinputText: '',
      removeConfirm: false,
      otpValue: '',
      isBtnDis: true,
      openVerification: false,
      activeIndex: 0,
      confirmPopup: false,
      contactDetailForUpdate: null,
      deleteSelectId: null,
      updatesContact: false,
      delteAddressConfirm: false,
      updateCompanyName: false,
      addAddress: false,
      custDetails: null,
    };
  },
  created() {},
  watch: {
    otpValue: {
      handler () {
        console.log('this.otpValue')
        if (this.otpValue.length > 4 || this.otpValue.length < 4) {
          this.isBtnDis = true
        } else {
          this.isBtnDis = false
        }
      }
    }
  },
  mounted() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.$root.$on('confirmBoxHandler', (data) => {
      if (data && this.confirmPopup) {
        this.rejectRequest()
      }
      document.body.style = 'overflow: visible;'
      this.confirmPopup = false
    })
    this.getCustDetails();
    this.getEmployeeList()
    this.$root.$on("globalOtpHandler", (data) => {
     if (data === null) {
      this.getCustDetails('notload')
     } else if (!data) {
        this.verifyEmail = false
      } else {
        this.getCustDetails()
        this.verifyEmail = false
        let obj = JSON.parse(localStorage.getItem('jobUserInfo'))
        obj.isEmailVerify = true
        this.activeUer = obj
        localStorage.setItem('jobUserInfo', JSON.stringify(obj))
        this.$root.$emit('manageProfileIcon', true)
      }
    })
  },
  methods: {
    sendOtp () {
      this.tokenGenerated = ''
      this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      MyJobApp.sendOTPEmailWeb(
          'miken2288@gmail.com',// this.custDetails.emailAddress,
          response => {
            console.log('response', response)
            this.tokenGenerated = response.Data
            this.$store.dispatch('SetAlert', { showAlert: true, message: "Sent OTP successfully", color: 'success' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
            this.verifyEmail = true
          },
          (error) => {
            this.verifyEmail = false
            this.$store.dispatch('SetAlert', { showAlert: true, message: error.message, color: 'error' })
            this.$store.dispatch('SetLoader', { status: false, message: '' })
          }
        )
    },
    selectNewCustomer (data) {
      if (data !== null) {
        this.employeeSearchText = data.fullName
        this.alternateId = data.userDetailId
      } else {
        this.employeeSearchText = ''
        this.alternateId = 0
      }
    },
    statusAppBtn (value) {
      if (value) {
        this.confirmPopup = true
      } else {
        this.$router.push({name: 'SettingEmployeesEdit', params: {empId: parseInt(this.$route.params.empId)}, query: {fromApp: true}})
      }
    },
    editEmployee () {
      this.openVerification = true
      // this.$router.push({name: 'SettingEmployeesEdit', params: {empId: parseInt(this.$route.params.empId)}, query: {fromApp: false}})
    },
    updateContact (detail) {
     this.updatesContact = true
     this.contactDetailForUpdate = detail
    },
    checkOtpValue() {
      if (!this.isBtnDis) {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
        MyJobApp.VerifyAppPin(
          this.otpValue,
          (response) => {
            if (response.Data === 'Verified') {
              this.openVerification = false
              this.otpValue = ''
              this.$router.push({name: 'SettingEmployeesEdit', params: {empId: parseInt(this.$route.params.empId)}, query: {fromApp: false}})
            }
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
            this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      }
    },
    closeDialog () {
      this.removeConfirm = false
    },
    getCustDetails(notload) {
      console.log("call", this.$route);
      if (!notload) {
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});  
      }
      MyJobApp.getSettingEmployeeDetail(
        parseInt(this.$route.params.empId),
        (response) => {
          this.custDetails = response.Data;
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    deleteAccount() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.DeleteEmployee(
        this.custDetails.userDetailId,
        this.alternateId,
        (response) => {
            document.body.style = 'overflow: visible;'
            removeCookies('jobAuthToken')
            localStorage.clear()
            this.$router.push({name: 'login'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            console.log('error', error);
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
      );
    },
    rejectRequest() {
      console.log("call", this.$route);
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      MyJobApp.rejectEmpRequest(
        parseInt(this.$route.params.empId),
        (response) => {
          this.$router.push({name: 'SettingEmployees'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getEmployeeList () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
              if (el.userDetailId !== parseInt(this.$route.params.empId)) {
                tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
              }
            })
          }
          this.employeeList = tempList
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  },
  beforeDestroy() {
    this.$root.$off('confirmBoxHandler')
    this.$root.$off("setNewTabActive");
    this.$root.$off("addContactHandler");
    this.$root.$off("globalOtpHandler");
  },
};
</script>
<style scoped>
</style>